import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { LazyBannerImage, placeholder, errorPlaceholder } from './LazyBannerImage';
import { LazyImage } from './LazyImage';
import ErrorRetry, { LoadingText, ErrorRetryOnlyBtn } from './ErrorRetry';

const useAds = () => {
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
  
    const fetchAds = async () => {
      setLoading(true); // 开始加载
      setError(false); // 重置错误状态
  
      try {
        await new Promise(resolve => setTimeout(resolve, 0));
  
        const response = await axios.get('/c.json');
        const data = response.data.ads;
  
        if (data) {
          const adsArray = Object.keys(data).map(key => ({ ...data[key], id: key }));
          setAds(adsArray);
        } else {
          console.error('Failed to fetch ads:', response.data);
          setError(true);
        }
      } catch (error) {
        console.error('Error fetching ads:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchAds();
    }, []);
  
    return { ads, loading, error, fetchAds };
};

export const BannerAds = () => {
    const { ads, loading, error, fetchAds } = useAds();
  
    if (loading) {
      return <LoadingText />;
    }
  
    if (error) {
      console.error("Banner Link Load Error...");
      return <ErrorRetry title={'Banner'} onClick={fetchAds} />;
    }
  
    let bannerAds = ads.filter(ad => ad.type === 'banner');

    if (bannerAds.length > 0) {
      const randomIndex = Math.floor(Math.random() * bannerAds.length);
      bannerAds = [bannerAds[randomIndex]];
    }
  
    return (
      <div className="banner min-h-[100px] w-full flex flex-wrap mb-4">
        {bannerAds.map(banner => (
          <Link
            className="w-full sm:w-1/2"
            key={banner.id}
            to={`/b/${banner.id}`}
            state={{
              src: banner.src,
              title: banner.title,
              href: banner.href,
              owner:banner.owner
            }}
          >
            <LazyBannerImage
              src={banner.src}
              alt={banner.title}
              placeholder={placeholder(398, 100)}
              errorPlaceholder={<div className="px-4 py-10 break-words border-2">{banner.title}</div>}
            />
          </Link>
        ))}
      </div>
    );
};

export const TextAds = ({ id }) => {
    const { ads, loading, error } = useAds();
  
    if (loading) {
      return <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">加载中...</div>;
    }
  
    if (error) {
      console.error("Text Link Load Error...");
      return <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">加载 Text.Link 失败，无妨，不影响看片</div>;
    }
  
    const textAds = ads.filter(ad => ad.type === 'text');
    let adToDisplay;

    if (id) {
        adToDisplay = textAds.find(ad => ad.id === id);
    } else {
        adToDisplay = textAds[Math.floor(Math.random() * textAds.length)];
    }

    if (!adToDisplay) {
      return <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">此处暂无内容，无妨，不影响看片</div>;
    }
  
    return (
      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">
        <Link to={`/b/${adToDisplay.id}`}
        state={{
          src: adToDisplay.src,
          title: adToDisplay.title,
          href: adToDisplay.href,
          owner:adToDisplay.owner
        }}
        >{adToDisplay.title}</Link>
      </div>
    );
};

export const TagAds = () => {
    const { ads, loading, error } = useAds();
  
    if (loading) {
      return <p className="border bg-sc text-tw px-2 py-1 text-center grow shrink basis-auto">...</p>;
    }
  
    if (error) {
      console.error("Tags Link Load Error...");
      return null;
    }
  
    const tagAds = ads.filter(ad => ad.type === 'tag');
    const modelAds = ads.filter(ad => ad.type === 'model');
    return (
      <>
        {tagAds.map(tagAds => (
          tagAds.id == "32" ? (
            <Link key={tagAds.id} to={`/c/${tagAds.id}`} 
            state={{
              src: modelAds[0].src,
              title: tagAds.title,
              href: modelAds[0].href,
              owner: modelAds[0].owner,
              detailsrc: modelAds[0].detailsrc,
              helpsrc: modelAds[0].helpsrc,
              buttontext: modelAds[0].buttontext
            }} 
           className="border bg-sc text-tw px-2 py-1 text-center grow shrink basis-auto">{tagAds.title}</Link>
          ):(
            <Link key={tagAds.id} to={`/b/${tagAds.id}`} 
            state={{
              src: tagAds.src,
              title: tagAds.title,
              href: tagAds.href,
              owner:tagAds.owner
            }} 
           className="border bg-sc text-tw px-2 py-1 text-center grow shrink basis-auto">{tagAds.title}</Link>
          )

        ))}
      </>
    );
};

export const NativeAds = ({ id }) => {
    const { ads, loading, error, fetchAds } = useAds();
  
    if (loading) {
      return (
        <div className="break-inside-avoid mb-4">
            <img
            className="w-full h-auto object-cover"
            src={placeholder(300,200)}
            alt="加载中..."
            width="300"
            height="200"
            />
            <p className="text-center mt-2 text-sm">加载中...</p>
        </div>
      );
    }
  
    if (error) {
      console.error("Native Link Load Error...");  
      return <ErrorRetryOnlyBtn onClick={fetchAds} />;
    }
  
    const nativeAds = ads.filter(ad => ad.type === 'native');
    let adToDisplay;

    if (id) {
        adToDisplay = nativeAds.find(ad => ad.id === id);
    } else {
        adToDisplay = nativeAds[Math.floor(Math.random() * nativeAds.length)];
    }

    if (!adToDisplay) {
        console.error("No Native Link...");
        return null;
    }
  
    return (
    <div key={adToDisplay.id} className="break-inside-avoid mb-4">
        <Link to={`/b/${adToDisplay.id}`}
        state={{
            src: adToDisplay.src,
            title: adToDisplay.title,
            href: adToDisplay.href,
            owner:adToDisplay.owner
            
            }}
            className="break-inside-avoid mb-4">
            <LazyImage
            className="w-full h-auto object-cover"
            src={adToDisplay.src}
            alt={adToDisplay.title}
            placeholder={placeholder(300,200)}
            errorPlaceholder={errorPlaceholder(300,200,adToDisplay.title)}
            width={300}
            height={200}
            />
            <p className="text-center mt-2 text-sm">{adToDisplay.title}</p>
        </Link>
    </div>
    );
};

// 暂停广告位
export const PauseAds = ({ id, visible }) => {
    const { ads, loading, error } = useAds();
  
    if (loading) {
    //   return (
    //     <div className="break-inside-avoid mb-4">
    //         <img
    //         className="w-full h-auto object-cover"
    //         src={placeholder(300,200)}
    //         alt="加载中..."
    //         width="300"
    //         height="200"
    //         />
    //         <p className="p-10">加载中...</p>
    //     </div>
    //   );
        return null;
    }
  
    if (error) {
      console.error("Pause Link Load Error...");
      return null;
    }
  
    const pauseAds = ads.filter(ad => ad.type === 'pause');
    let adToDisplay;

    if (id) {
        adToDisplay = pauseAds.find(ad => ad.id === id);
    } else {
        adToDisplay = pauseAds[Math.floor(Math.random() * pauseAds.length)];
    }

    if (!adToDisplay) {
      console.error("No Pause Link...");
      return null;
    }
  
    return visible ? (
        <div className="break-inside-avoid mb-4">
            <Link to={`/b/${adToDisplay.id}`}
            state={{
                src: adToDisplay.src,
                title: adToDisplay.title,
                href: adToDisplay.href,
                owner:adToDisplay.owner
                }}
             className="break-inside-avoid mb-4">
            <LazyImage
                className="w-full h-auto object-cover"
                src={adToDisplay.src}
                alt={adToDisplay.title}
                placeholder={placeholder(300,200)}
                errorPlaceholder={errorPlaceholder(300,200,adToDisplay.title)}
                width={300}
                height={200}
                />
            </Link>
        </div>
    ) : null;
};


export const GridAds = () => {
  const { ads, loading, error } = useAds();

  if (error) {
    console.error("Tags Link Load Error...");
    return null;
  }

  const iconAds = ads.filter(ad => ad.type === 'icon');
  const modelAds = ads.filter(ad => ad.type === 'model');
  return (
    <>
    <div className="flex min-h-[400px] flex-wrap">
      {iconAds.map(iconAds => (
        iconAds.id == "22" ? (
          <Link key={iconAds.id}  to={`/c/${iconAds.id}`} 
            state={{
              src: modelAds[0].src,
              title: iconAds.title,
              href: modelAds[0].href,
              owner: modelAds[0].owner,
              detailsrc: modelAds[0].detailsrc,
              helpsrc: modelAds[0].helpsrc,
              buttontext: modelAds[0].buttontext
            }} 
            className="basis-1/4 sm:basis-1/6 md:basis-1/8 lg:basis-1/10 text-tw px-2 py-1 text-center">
            <LazyBannerImage
              src={iconAds.src}
              alt={iconAds.title}
              placeholder={placeholder('96px', '96px')}
              width={'96px'}
              height={'96px'}
              title={iconAds.title}
              errorPlaceholder={<div className="px-4 py-4 break-words border-2">{iconAds.title}</div>}
            />
          </Link>
        ) : (
        <Link key={iconAds.id} to={`/b/${iconAds.id}`} 
        state={{
          src: iconAds.src,
          title: iconAds.title,
          href: iconAds.href,
          owner:iconAds.owner,
        }} 
       className="basis-1/4 sm:basis-1/6 md:basis-1/8 lg:basis-1/10 text-tw px-2 py-1 text-center">
        <LazyBannerImage
              src={iconAds.src}
              alt={iconAds.title}
              placeholder={placeholder('96px', '96px')}
              width={'96px'}
              height={'96px'}
              title={iconAds.title}
              errorPlaceholder={<div className="px-4 py-4 break-words border-2">{iconAds.title}</div>}
            />
       </Link>
      )))}
      </div>
    </>
  );
};